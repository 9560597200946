import { metaMask } from 'connectors/metaMask'
import { walletConnectV2 } from 'connectors/walletConnect'
import { createContext, useContext } from 'react'

interface Props {
  networkName: string
  unsupportedChain: boolean
  handleMetaMaskConnect: () => void
  handleWalletConnect: () => void
  handleDisconnect: () => void
}

export const WalletContext = createContext<Props>({
  networkName: '',
  unsupportedChain: false,
  handleMetaMaskConnect: () => {
    metaMask.activate()
  },
  handleWalletConnect: () => {
    walletConnectV2.resetState()
    walletConnectV2.deactivate()
    walletConnectV2.activate()
  },
  handleDisconnect: () => {
    metaMask.resetState()
    walletConnectV2.resetState()
    walletConnectV2.deactivate()
  },
})

export function useWallet(): Props {
  return useContext(WalletContext)
}
