import React, { useState } from "react";
import styled from "styled-components";
import {
	Container, Button, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,
	Card, CardText, Input, Label, CardBody, Spinner
} from "reactstrap";
import classnames from "classnames";
import { POOLS, TOKENS, TOKEN_LOGO } from "constant";
import { formatBigNumber, formatMillionsBigNumber, getEtherscanLink } from "utils";
import {
	useActiveWeb3React, useBalance, useERC20Contract, useBentStakingAllowanceV2, useBentStakedV2,
	useBentStakedUSDV2, useBentTotalStakedV2, useBentPoolAprV2, useBentTotalEarnedV2, useBentTvlV2,
	useBentStakingContractV2
} from "hooks";
import { BigNumber, ethers, utils } from "ethers";
import { DecimalSpan } from "components/DecimalSpan";
import Address from "components/Address";
import { AddToMetamask } from "components/AddToMetamask";
import { ClaimBentRewarder } from "./rewarderBent";
import { BentAprTooltipV2 } from "components/Tooltip";

const TxSpinner = () => {
	return (
		<React.Fragment>
			&nbsp;
			<Spinner size="sm" />
		</React.Fragment>
	)
}

export const StakeBentV2 = (): React.ReactElement => {
	const [activeTab, setActiveTab] = useState("1");
	const [stakeAmount, setStakeAmount] = useState('');
	const [withdrawAmount, setWithdrawAmount] = useState('');
	const [isStakeApproved, setIsStakeApproved] = useState<boolean>(false);
	const [isStkApprPending, setStkApprPending] = useState<boolean>(false);
	const [isStkPending, setStkPending] = useState<boolean>(false);
	const [isUnstakePending, setUnstakePending] = useState<boolean>(false);
	const bentToken = useERC20Contract(TOKENS['BENT'].ADDR);
	const bentBalance = useBalance(TOKENS['BENT'].ADDR);
	const bentStakingAllowance = useBentStakingAllowanceV2();
	const bentStaked = useBentStakedV2();
	const bentStakedUsd = useBentStakedUSDV2();
	const bentTotalStaked = useBentTotalStakedV2();
	const bentPoolApr = useBentPoolAprV2('BENTCVX');
	const earnedUsd = useBentTotalEarnedV2();
	const tvl = useBentTvlV2();
	const { library } = useActiveWeb3React();
	const bentStaking = useBentStakingContractV2();

	const visible = !bentStaked.isZero() || !earnedUsd.isZero()

	if (!visible) {
		return <></>
	}

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const onStakeMax = () => {
		setStakeAmount(formatBigNumber(bentBalance, 18, 18).replaceAll(',', ''));
		setIsStakeApproved(bentStakingAllowance.gte(bentBalance) && !bentBalance.isZero());
	}

	const onWithdrawMax = () => {
		setWithdrawAmount(formatBigNumber(bentStaked, 18, 18).replaceAll(',', ''));
	}

	const onStakeAmountChange = (value) => {
		setStakeAmount(value);
		if (isNaN(parseFloat(value))) return;
		const amountBN = utils.parseUnits(value, 18);
		setIsStakeApproved(bentStakingAllowance.gte(amountBN) && !amountBN.isZero());
	}

	const onWithdrawAmountChange = (value) => {
		setWithdrawAmount(value);
	}

	const onBentApprove = async () => {
		if (!library) return;
		const signer = await library.getSigner();
		const tx = await bentToken.connect(signer).approve(POOLS.BentStakingV2.BentStaking, ethers.constants.MaxUint256);
		setStkApprPending(true);
		const res = await tx.wait();
		setStkApprPending(false);
		if (res) {
			setIsStakeApproved(true);
		}
	}

	const onStake = async () => {
		if (!library) return;
		const signer = await library.getSigner();
		const tx = await bentStaking.connect(signer).deposit(utils.parseUnits(stakeAmount, 18));
		setStkPending(true);
		const res = await tx.wait();
		setStkPending(false);
		if (res) {
			setStakeAmount('')
			setIsStakeApproved(false);
		}
	}

	const onWithdraw = async () => {
		if (!library) return;
		const signer = await library.getSigner();
		const tx = await bentStaking.connect(signer).withdraw(utils.parseUnits(withdrawAmount, 18));
		setUnstakePending(true);
		const res = await tx.wait();
		setUnstakePending(false);
		if (res) {
			setWithdrawAmount('')
		}
	}

	return (
		<Container className="stake-bent bentCVX">
			<Row>
				<Col md="12">
					<div className="convert-up">
						<h2 className="section-header">
							Stake BENT to earn bentCVX
						</h2>
						<div className="toggleWrap tokentable table">
							<Row className="align-items-center thead">
								<Col className="px-0">
									<div className="imgText">
										<img src={TOKEN_LOGO.BENT} alt="" width="28" />
										<h2>BENT</h2>
									</div>
								</Col>
								<Col>
									<div>
										<span className="small p-0">Earned (USD)</span><br />
										<b className="p-0">
											<span className="small">$</span>
											<DecimalSpan value={formatBigNumber(earnedUsd, 18, 2)} />
											<i className="fa fa-caret-down opacity-0" aria-hidden="true" />
										</b>
									</div>
								</Col>
								<Col>
									<div>
										<span className="small p-0">APR</span><br />
										<b className="p-0">
											{bentPoolApr ? <>{utils.commify(bentPoolApr.toFixed(2))}%</> : 'TBC'}&nbsp;
											<i className="fa fa-info-circle cursor-pointer" id="bent-apr-info-v2" aria-hidden="true" />
											<BentAprTooltipV2 />
										</b>
									</div>
								</Col>
								<Col>
									<div>
										<span className="small p-0">My Staked ({bentStaked.isZero() ? '--' : formatBigNumber(bentStaked, 18, 2)} BENT)</span><br />
										<b className="p-0">
											<span className="small">$</span>
											<DecimalSpan value={formatBigNumber(bentStakedUsd, 18, 2)} />
											<i className="fa fa-caret-down opacity-0" aria-hidden="true" />
										</b>
									</div>
								</Col>
								<Col>
									<div>
										<span className="small p-0">TVL ({formatBigNumber(bentTotalStaked, 18, 2)} BENT)</span><br />
										<b className="p-0">
											<span className="small">$</span>
											{formatMillionsBigNumber(tvl, 18, 2)}
											<i className="fa fa-caret-down opacity-0" aria-hidden="true" />
										</b>
									</div>
								</Col>
							</Row>
							<Card>
								<CardBody>
									<div className="innerAccordian">
										<div className="converttabs">
											<Nav tabs>
												<NavItem>
													<NavLink
														className={classnames({ active: activeTab === "1" })}
														onClick={() => toggle("1")}
													>Stake</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({ active: activeTab === "2" })}
														onClick={() => toggle("2")}
													>Claim</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({ active: activeTab === "3" })}
														onClick={() => toggle("3")}
													>Unstake</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({ active: activeTab === "4" })}
														onClick={() => toggle("4")}
													>Info</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={activeTab}>
												<TabPane tabId="1">
													<Row>
														<Col sm="6" className="inverse">
															<Card body>
																<CardText className="mt-0">
																	Stake BENT to earn a portion of the platform's revenue, distributed as bentCVX tokens.
																</CardText>
															</Card>
														</Col>
														<Col sm="6" className="divider-left">
															<Card body>
																<div className="card-text">
																	<div className="amount-crv">
																		<p className="labeltext">
																			<Label>
																				Amount
																			</Label>
																			<Label>Available: {formatBigNumber(bentBalance)}</Label>
																		</p>
																		<div className="amountinput">
																			<Input
																				type="number" placeholder="0"
																				onChange={(e) => onStakeAmountChange(e.target.value)}
																				value={stakeAmount}
																			/>
																			<img src={TOKEN_LOGO.BENT} alt="input-logo" className="inputlogo" />
																			<Button className="maxbtn" onClick={onStakeMax} >Max</Button>
																		</div>
																		<div className="btnouter">
																			<p className="lineup"></p>
																			<div className="btnwrapper">
																				<Button
																					className="approvebtn"
																					disabled={
																						bentBalance.isZero() || isStakeApproved ||
																						parseFloat(stakeAmount) === 0 || isNaN(parseFloat(stakeAmount)) ||
																						utils.parseUnits(stakeAmount, 18).gt(bentBalance) ||
																						isStkApprPending
																					}
																					onClick={onBentApprove}
																				>Approve{isStkApprPending && <TxSpinner />}</Button>
																				<Button
																					className="approvebtn"
																					disabled={true}
																					onClick={onStake}
																				>Stake{isStkPending && <TxSpinner />}</Button>
																			</div>
																		</div>
																	</div>
																</div>
															</Card>
														</Col>
													</Row>
												</TabPane>
												<TabPane tabId="2">
													<Row>
														<ClaimBentRewarder
															poolKey="BENTCVX"
															poolInfo={POOLS.BentStakingV2.BentRewarder}
														/>
													</Row>
												</TabPane>
												<TabPane tabId="3">
													<Row>
														<Col md="12" className="inverse">
															<Card body>
																<div className="card-text d-flex row">
																	<div className="amount-crv col-md-5">
																		<p className="labeltext">
																			<Label>
																				Amount
																			</Label>
																			<Label>Deposited:{formatBigNumber(bentStaked)}</Label>
																		</p>
																		<div className="amountinput">
																			<Input
																				type="number" placeholder="0"
																				onChange={(e) => onWithdrawAmountChange(e.target.value)}
																				value={withdrawAmount}
																			/>
																			<img src={TOKENS['BENT'].LOGO} alt="input-logo" className="inputlogo" />
																			<Button className="maxbtn" onClick={onWithdrawMax} >Max</Button>
																		</div>
																	</div>
																	<div className="amount-crv col-md-5">
																		<p className="labeltext">
																			<Label>
																				&nbsp;
																			</Label>
																		</p>
																		<Button
																			className="approvebtn"
																			disabled={
																				bentStaked.isZero() ||
																				parseFloat(withdrawAmount) === 0 || isNaN(parseFloat(withdrawAmount)) ||
																				utils.parseUnits(withdrawAmount, 18).gt(bentStaked) ||
																				isUnstakePending
																			}
																			onClick={onWithdraw}
																		>Unstake{isUnstakePending && <TxSpinner />}</Button>
																	</div>
																</div>
															</Card>
														</Col>
													</Row>
												</TabPane>
												<TabPane tabId="4">
													<Row>
														<Col sm="12">
															<Card body>
																<div className="infoWrap">
																<Card body>
															</Card>
																	<Row>
																		<Col md="3">
																			<CardText className="mt-0">
																				<b>BENT</b> token address:
																			</CardText>
																		</Col>
																		<Col md="9" className="d-flex">
																			<a href={getEtherscanLink(TOKENS.BENT.ADDR)} target="_blank" rel="noreferrer">
																				<Address address={TOKENS.BENT.ADDR} />
																			</a>
																			<AddToMetamask
																				address={TOKENS.BENT.ADDR}
																				symbol={TOKENS.BENT.SYMBOL}
																				img={TOKENS.BENT.EXT_LOGO}
																			/>
																		</Col>
																	</Row>
																	<Row>
																		<Col md="3">
																			<CardText className="mt-0">
																				<b>bentCVX</b> token address:
																			</CardText>
																		</Col>
																		<Col md="9">
																			<a href={getEtherscanLink(TOKENS.BENTCVX.ADDR)} target="_blank" rel="noreferrer">
																				<Address address={TOKENS.BENTCVX.ADDR} />
																			</a>
																		</Col>
																	</Row>
																	<Row>
																		<Col md="3">
																			<CardText className="mt-0">
																				Deposit contract address:
																			</CardText>
																		</Col>
																		<Col md="9">
																			<a href={getEtherscanLink(POOLS.BentStakingV2.BentStaking)} target="_blank" rel="noreferrer">
																				<Address address={POOLS.BentStakingV2.BentStaking} />
																			</a>
																		</Col>
																	</Row>
																	<Row>
																		<Col md="3">
																			<CardText className="mt-0">
																				Rewards contract address:
																			</CardText>
																		</Col>
																		<Col md="9">
																			<a href={getEtherscanLink(POOLS.BentStakingV2.BentRewarder.Pool)} target="_blank" rel="noreferrer">
																				<Address address={POOLS.BentStakingV2.BentRewarder.Pool} />
																			</a>
																		</Col>
																	</Row>
																</div>
															</Card>
														</Col>
													</Row>
												</TabPane>
											</TabContent>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	)
}


const ClaimButton = styled(Button)`
	margin-left: 20px;
	height: 42px;
	width: 150px;
`