import React from 'react'
import styled from 'styled-components'
import addSvg from 'assets/images/plus.svg'
import { Icon } from 'components/Icon'
import { useWeb3React } from '@web3-react/core'

interface Props {
  address: string | undefined | null
  symbol?: string
  img?: string
  showTitle?: boolean
}

export const AddToMetamask = (props: Props): React.ReactElement => {
  const { connector } = useWeb3React()

  function addToMetamask() {
    connector.watchAsset?.({
      address: props.address as string,
      symbol: props.symbol as string,
      decimals: 18,
      // image: 'https://assets.coingecko.com/coins/images/21274/small/bent-logo-200x200.png?1638861325',
      image: props.img as string,
    })
  }

  return (
    <Container onClick={addToMetamask}>
      <Icon
        iconSrc={addSvg}
        borderColor="#CAB8FF"
        style={{ marginLeft: 0, margin: props.showTitle ? 10 : 0, width: 25, height: 25 }}
        highlight={true}
      />
      {props.showTitle && 'Add to Metamask'}
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #c1eaf8;
  text-decoration: none;
  cursor: pointer;
  margin-left: 10px;
`
