import { useSelector } from 'react-redux';
import { BigNumber, ethers, utils } from 'ethers';
import { AppState } from '../../index';
import { useTokenPrice } from '.';
import { TOKENS } from 'constant';
import { getTokenDecimals } from 'utils';

export const useBentCvxAllowanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxAllowanceV2 || ethers.constants.Zero));
}

export const useCvxStakingAllowanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.cvxStakingAllowanceV2 || ethers.constants.Zero));
}

export const useVlCvxBalanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.vlCvxBalanceV2 || ethers.constants.Zero));
}

export const useBentCvxStakingAllowanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxStakingAllowanceV2 || ethers.constants.Zero));
}

export const useBentCvxStakedV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxStakedV2 || ethers.constants.Zero));
}

export const useBentCvxTotalStakedV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxTotalStakedV2 || ethers.constants.Zero));
}

export const useBentCvxTvlV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxTvlV2 || ethers.constants.Zero));
}

export const useBentCvxStakedUSDV2 = (): BigNumber => {
	const bentCvxStaked = useBentCvxStakedV2();
	const bentCvxPrice = useTokenPrice(TOKENS.BENTCVX.ADDR);
	return utils.parseEther(bentCvxPrice.toString()).mul(bentCvxStaked).div(BigNumber.from(10).pow(getTokenDecimals(TOKENS.BENTCVX.ADDR)));
}

export const useBentCvxAllRewardsV2 = (): Record<string, BigNumber[]> => {
	return useSelector((state: AppState) => state.contracts.bentCvxRewardsV2 ?? {});
}

export const useBentCvxRewardsV2 = (poolKey: string): BigNumber[] => {
	return useSelector((state: AppState) => state.contracts.bentCvxRewardsV2 ? state.contracts.bentCvxRewardsV2[poolKey] || [] : []);
}

export const useBentCvxRewardsUsdV2 = (poolKey: string): BigNumber[] => {
	return useSelector((state: AppState) => state.contracts.bentCvxRewardsUsdV2 ? (state.contracts.bentCvxRewardsUsdV2[poolKey] || []) : []);
}

export const useBentCvxEarnedV2 = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentCvxEarnedV2 ? state.contracts.bentCvxEarnedV2[poolKey] || ethers.constants.Zero : ethers.constants.Zero));
}

export const useBentCvxTotalEarnedV2 = (): BigNumber => {
	return useBentCvxEarnedV2('CVX').add(useBentCvxEarnedV2('BENT')).add(useBentCvxEarnedV2('MC')).add(useBentCvxEarnedV2('MC_OLD'));
}

export const useBentCvxAvgAprV2 = (): number => {
	return useSelector((state: AppState) => state.contracts.bentCvxAvgAprV2 || 0);
}

export const useBentCvxPoolAprV2 = (poolKey: string): number => {
	return useSelector((state: AppState) => state.contracts.bentCvxPoolAprsV2 ? state.contracts.bentCvxPoolAprsV2[poolKey] || 0 : 0);
}

export const useBentCvxAprsV2 = (poolKey: string): number[] => {
	return useSelector((state: AppState) => state.contracts.bentCvxAprsV2 ? (state.contracts.bentCvxAprsV2[poolKey] || []) : []);
}