import React from 'react'
import { Helmet } from 'react-helmet-async'
import 'font-awesome/css/font-awesome.min.css'
import { StakeCurveLpTable } from 'components/StakeCurveLpTable'
// import { StakeBentCVX } from 'components/StakeBentCVX';
import { StakeBentCVXV2 } from 'components/StakeBentCVXV2'
import { StakeBent } from 'components/StakeBent'
import { StakeBentV2 } from 'components/StakeBentV2'
import { StakeSushiLpTable } from 'components/StakeSushiLpTable'
import BannerBlocks from 'components/BannerBlocks'
import { WeBentStatus } from 'components/WeBentStatus'
import { StakeUniswapLpTable } from 'components/StakeUniswapLpTable'
import { StakeBentCVXLpV2 } from 'components/StakeBentCVXLpV2'

const Stake = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Bent Finance | Pools</title>
      </Helmet>
      <div className="banner">
        <div className="bannerboxes">
          <BannerBlocks />
        </div>
      </div>

      <div className="contentSection">
        <WeBentStatus />
        <div className="section">
          <StakeBentCVXLpV2 />
        </div>
        <div className="section">
          <StakeBentCVXV2 />
        </div>
        {/* <div className="section">
          <StakeBentCVX />
        </div> */}
        <div className="section">
          <StakeUniswapLpTable />
        </div>
        <div className="section">
          <StakeCurveLpTable />
        </div>
        <div className="section">
          <StakeBentV2 />
        </div>
        <div className="section">
          <StakeBent />
        </div>
        <div className="section">
          <StakeSushiLpTable />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Stake
