import { useSelector } from 'react-redux';
import { BigNumber, ethers } from 'ethers';
import { AppState } from '../../index';

export const useUniswapLpDeposited = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => state.contracts.uniswapLpDeposited ? BigNumber.from(state.contracts.uniswapLpDeposited[poolKey] || ethers.constants.Zero) : ethers.constants.Zero);
}

export function useUniswapTvl(poolKey: string): BigNumber {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.uniswapTvl[poolKey] || ethers.constants.Zero));
}

export const useUniswapTotalTvl = (): BigNumber => {
	let total = ethers.constants.Zero;
	const tvls = useSelector((state: AppState) => state.contracts.uniswapTvl || {});
	Object.keys(tvls).forEach(poolKey => {
		total = total.add(BigNumber.from(tvls[poolKey] || ethers.constants.Zero));
	})
	return total;
}

export function useUniswapApr(poolKey: string): number {
	return useSelector((state: AppState) => state.contracts.uniswapApr ? state.contracts.uniswapApr[poolKey] ?? 0 : 0);
}

export const useUniswapPoolRewards = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => state.contracts.uniswapRewards ? BigNumber.from(state.contracts.uniswapRewards[poolKey] || ethers.constants.Zero) : ethers.constants.Zero);
}

export const useUniswapPoolTotalEarned = (): BigNumber => {
	let total = ethers.constants.Zero;
	const earns = useSelector((state: AppState) => state.contracts.uniswapEarnedUsd || {});
	Object.keys(earns).forEach(poolKey => {
		total = total.add(BigNumber.from(earns[poolKey] || ethers.constants.Zero));
	})
	return total;
}

export const useUniswapPoolEarnedUsd = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => state.contracts.uniswapEarnedUsd ? BigNumber.from(state.contracts.uniswapEarnedUsd[poolKey] || ethers.constants.Zero) : ethers.constants.Zero);
}

export const useUniswapPoolDepositedUsd = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => state.contracts.uniswapDepositedUsd ? BigNumber.from(state.contracts.uniswapDepositedUsd[poolKey] || ethers.constants.Zero) : ethers.constants.Zero);
}

export const useUniswapPoolTotalDepositedUsd = (): BigNumber => {
	let total = ethers.constants.Zero;
	const earns = useSelector((state: AppState) => state.contracts.uniswapDepositedUsd || {});
	Object.keys(earns).forEach(poolKey => {
		total = total.add(BigNumber.from(earns[poolKey] || ethers.constants.Zero));
	})
	return total;
}
