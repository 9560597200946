import { useSelector } from 'react-redux';
import { BigNumber, ethers, utils } from 'ethers';
import { AppState } from '../../index';
import { useTokenPrice } from '.';
import { TOKENS } from 'constant';
import { getTokenDecimals } from 'utils';

export const useBentBalanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentBalanceV2 || ethers.constants.Zero));
}

export const useBentStakingAllowanceV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentStakingAllowanceV2 || ethers.constants.Zero));
}

export const useBentStakedV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentStakedV2 || ethers.constants.Zero));
}

export const useBentTotalStakedV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentTotalStakedV2 || ethers.constants.Zero));
}

export const useBentTvlV2 = (): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentTvlV2 || ethers.constants.Zero));
}

export const useBentStakedUSDV2 = (): BigNumber => {
	const bentStaked = useBentStakedV2();
	const bentPrice = useTokenPrice(TOKENS.BENT.ADDR);
	return utils.parseEther(bentPrice.toString()).mul(bentStaked).div(BigNumber.from(10).pow(getTokenDecimals(TOKENS.BENT.ADDR)));
}

export const useBentAllRewardsV2 = (): Record<string, BigNumber[]> => {
	return useSelector((state: AppState) => state.contracts.bentRewardsV2 ?? {});
}

export const useBentRewardsV2 = (poolKey: string): BigNumber[] => {
	return useSelector((state: AppState) => state.contracts.bentRewardsV2 ? state.contracts.bentRewardsV2[poolKey] || [] : []);
}

export const useBentRewardsUsdV2 = (poolKey: string): BigNumber[] => {
	return useSelector((state: AppState) => state.contracts.bentRewardsUsdV2 ? (state.contracts.bentRewardsUsdV2[poolKey] || []) : []);
}

export const useBentEarnedV2 = (poolKey: string): BigNumber => {
	return useSelector((state: AppState) => BigNumber.from(state.contracts.bentEarnedV2 ? state.contracts.bentEarnedV2[poolKey] || ethers.constants.Zero : ethers.constants.Zero));
}

export const useBentTotalEarnedV2 = (): BigNumber => {
	return useBentEarnedV2('BENTCVX');
}

export const useBentAvgAprV2 = (): number => {
	return useSelector((state: AppState) => state.contracts.bentAvgAprV2 || 0);
}

export const useBentPoolAprV2 = (poolKey: string): number => {
	return useSelector((state: AppState) => state.contracts.bentPoolAprsV2 ? state.contracts.bentPoolAprsV2[poolKey] || 0 : 0);
}

export const useBentAprsV2 = (poolKey: string): number[] => {
	return useSelector((state: AppState) => state.contracts.bentAprsV2 ? (state.contracts.bentAprsV2[poolKey] || []) : []);
}
