import React, { useState } from "react";
import styled from "styled-components";
import {
	Row, Col, Button, Spinner
} from "reactstrap";
import { DecimalSpan } from "components/DecimalSpan";
import { BigNumber, ethers, utils } from 'ethers';
import { TOKENS } from "constant";
import { useActiveWeb3React, useBentCvxEarnedV2, useBentCvxPoolAprV2, useBentCvxRewarderCvxContractV2, useBentCvxRewardsV2 } from "hooks";
import { formatBigNumber } from "utils";

interface Props {
	poolKey: string
	poolInfo: {
		Pool: string,
		RewardsAssets: string[],
	}
	onClaimCheckChange: (key, indexes) => void
	old?: boolean;
}

const TxSpinner = () => {
	return (
		<React.Fragment>
			&nbsp;
			<Spinner size="sm" />
		</React.Fragment>
	)
}

export const ClaimBentCvxRewarderCvx = (props: Props): React.ReactElement => {
	const [isClaimPending, setClaimPending] = useState<boolean>(false);
	const earned = useBentCvxEarnedV2(props.poolKey);
	const rewards = useBentCvxRewardsV2(props.poolKey);
	const apr = useBentCvxPoolAprV2(props.poolKey);
	const { library } = useActiveWeb3React();
	const bentCvxRewarder = useBentCvxRewarderCvxContractV2();

	const onClaim = async () => {
		if (!library) return;
		const signer = await library.getSigner();
		const address = await signer.getAddress();
		const tx = await bentCvxRewarder.connect(signer).claim(address, [0]);
		setClaimPending(true)
		await tx.wait();
		setClaimPending(false)
	}

	return (
		<div className={`innerWrap p-0 rounded position-relative`} >
			<Wrapper
				className={`bentInner`}
				color="primary"
				id={`toggleInner-claim-bentCvx-${props.poolKey}`}
				collapsed={false}
			>
				<Row className="align-items-center" style={{ padding: '0 10px' }}>
					<Col>
						<div className="imgText">
							<PoolLogo src={TOKENS.CVX.LOGO} alt="" />
							<h4>CVX</h4>
						</div>
					</Col>
					<Col>
						<b>
							<span className="small">$</span>
							<DecimalSpan value={formatBigNumber(earned, 18, 2)} />
						</b><br />
						<span className="small text-muted">
							{formatBigNumber(BigNumber.from(rewards.length ? rewards[0] : ethers.constants.Zero), 18, 2)} CVX
						</span>
					</Col>
					<Col>
						<b>
							{apr ? <>{utils.commify(apr.toFixed(2))}%</> : 'TBC'}
						</b>
					</Col>
					<Col></Col>
					<Col>
					<ClaimButton
							disabled={earned.isZero()}
							onClick={onClaim}
							className="approvebtn"
						>Claim{isClaimPending && <TxSpinner />}</ClaimButton>
					</Col>
				</Row>
			</Wrapper>
		</div>
	)
}

const PoolLogo = styled.img`
	max-width: 100px;
	height: 28px;
`

const Wrapper = styled.div<{ collapsed: boolean }>`
	cursor: pointer;
	padding: 10px;
`;

const ClaimButton = styled(Button)`
	margin-left: 20px;
	height: 42px;
	width: 150px;
`