import React from 'react'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

export const BentCVXLpAprTooltipV2 = (): React.ReactElement => {
  return (
    <UncontrolledTooltip
      target="bentCVXLp-apr-info-v2"
      className="bent-details"
      placement="bottom"
    >
      <div style={{ padding: 15, lineHeight: '18px' }}>
        <Row>
          <Col>
            <div className="text-underline">Reward Token:</div>
            <div className="green-color">CVX</div>
          </Col>
        </Row>
      </div>
    </UncontrolledTooltip>
  )
}
