import { useSelector } from 'react-redux'
import { BigNumber, ethers, utils } from 'ethers'
import { AppState } from '../../index'
import { useTokenPrice } from '.'
import { TOKENS } from 'constant'
import { getTokenDecimals } from 'utils'

export const useBentCVXLpBalanceV2 = (): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(state.contracts.bentCVXLpBalanceV2 || ethers.constants.Zero),
  )
}

export const useBentCVXLpStakingAllowanceV2 = (): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(state.contracts.bentCVXLpStakingAllowanceV2 || ethers.constants.Zero),
  )
}

export const useBentCVXLpStakedV2 = (): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(state.contracts.bentCVXLpStakedV2 || ethers.constants.Zero),
  )
}

export const useBentCVXLpTotalStakedV2 = (): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(state.contracts.bentCVXLpTotalStakedV2 || ethers.constants.Zero),
  )
}

export const useBentCVXLpTvlV2 = (): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(state.contracts.bentCVXLpTvlV2 || ethers.constants.Zero),
  )
}

export const useBentCVXLpStakedUSDV2 = (): BigNumber => {
  const bentCVXLpStaked = useBentCVXLpStakedV2()
  const bentCVXLpPrice = useTokenPrice(TOKENS.BENTCVX_LP.ADDR)
  return utils
    .parseEther(bentCVXLpPrice.toString())
    .mul(bentCVXLpStaked)
    .div(BigNumber.from(10).pow(getTokenDecimals(TOKENS.BENTCVX_LP.ADDR)))
}

export const useBentCVXLpAllRewardsV2 = (): Record<string, BigNumber[]> => {
  return useSelector((state: AppState) => state.contracts.bentCVXLpRewardsV2 ?? {})
}

export const useBentCVXLpRewardsV2 = (poolKey: string): BigNumber[] => {
  return useSelector((state: AppState) => {
    return Object.keys(state.contracts.bentCVXLpRewardsV2).length
      ? state.contracts.bentCVXLpRewardsV2[poolKey] || []
      : []
  })
}

export const useBentCVXLpRewardsUsdV2 = (poolKey: string): BigNumber[] => {
  return useSelector((state: AppState) =>
    state.contracts.bentCVXLpRewardsUsdV2
      ? state.contracts.bentCVXLpRewardsUsdV2[poolKey] || []
      : [],
  )
}

export const useBentCVXLpEarnedV2 = (poolKey: string): BigNumber => {
  return useSelector((state: AppState) =>
    BigNumber.from(
      state.contracts.bentCVXLpEarnedV2
        ? state.contracts.bentCVXLpEarnedV2[poolKey] || ethers.constants.Zero
        : ethers.constants.Zero,
    ),
  )
}

export const useBentCVXLpTotalEarnedV2 = (): BigNumber => {
  return useBentCVXLpEarnedV2('CVX')
}

export const useBentCVXLpAvgAprV2 = (): number => {
  return useSelector((state: AppState) => state.contracts.bentCVXLpAvgAprV2 || 0)
}

export const useBentCVXLpPoolAprV2 = (poolKey: string): number => {
  return useSelector((state: AppState) =>
    state.contracts.bentCVXLpPoolAprsV2 ? state.contracts.bentCVXLpPoolAprsV2[poolKey] || 0 : 0,
  )
}

export const useBentCVXLpAprsV2 = (poolKey: string): number[] => {
  return useSelector((state: AppState) =>
    state.contracts.bentCVXLpAprsV2 ? state.contracts.bentCVXLpAprsV2[poolKey] || [] : [],
  )
}
