/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from 'constant'
import { simpleRpcProvider } from 'utils/providers'
import { ethers } from 'ethers'

export type Web3Ethers = ReturnType<typeof useWeb3React> & {
  library?: Web3Provider
  chainId?: ChainId
}

export const useEthers = (): Web3Ethers => useWeb3React<Web3Provider>()

export const useActiveWeb3React = () => {
  const { connector, chainId, ...web3React } = useWeb3React()

  const initProvider = () => {
    if (connector.provider === undefined) {
      return simpleRpcProvider
    } else if (chainId === ChainId.Mainnet) {
      return new ethers.providers.Web3Provider(connector.provider) || simpleRpcProvider
    } else {
      return simpleRpcProvider
    }
  }
  const [provider, setProvider] = useState<any>(initProvider())

  useEffect(() => {
    if (connector.provider === undefined) {
      setProvider(simpleRpcProvider)
    } else if (chainId === ChainId.Mainnet) {
      setProvider(new ethers.providers.Web3Provider(connector.provider) || simpleRpcProvider)
    } else {
      setProvider(simpleRpcProvider)
    }
  }, [connector, chainId])

  return { library: provider, chainId: chainId ?? ChainId.Mainnet, ...web3React }
}
