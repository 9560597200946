import { BigNumber, ethers } from 'ethers';
import { createReducer } from '@reduxjs/toolkit';
import { updateContractInfo } from './actions';

export interface BentPoolReward {
	rewardRate: BigNumber,
	rewardToken: string,
}

export interface CrvApy {
	baseCrvvApr: BigNumber;
	crvvApr: BigNumber;
	cvxvApr: BigNumber;
	bentApr: BigNumber;
	additionalRewardvApr: BigNumber;
	crvBoost: number;
}

export interface Voter {
	id: string;
	voter: string;
	vp: number;
	ens?: string;
}

export interface WeBentLockedData {
	amount: BigNumber;
	unlockAt: BigNumber;
}

export interface ContractsState {
	gas: BigNumber,

	bentCirculatingSupply: BigNumber,
	tokenPrices: Record<string, number>

	balances: Record<string, BigNumber>;
	totalSupplies: Record<string, BigNumber>;

	// weBent Pool States
	weBentAllowance: BigNumber;
	weBentBalance: BigNumber;
	weBentLocked: BigNumber;
	weBentTotalSupply: BigNumber;
	weBentBentBalance: BigNumber;
	weBentTvl: BigNumber;
	weBentLockedData: WeBentLockedData[];
	weBentUnlockable: BigNumber;
	weBentLockDuration: BigNumber;
	weBentApr: number,
	weBentAvgApr: number,
	weBentEarnedUsd: BigNumber,
	weBentAprs: Record<string, number>,
	weBentRewards: Record<string, BigNumber>,
	weBentRewardsUsd: Record<string, BigNumber>,
	delegationAddr: string;

	// Bent Staking Pool States
	bentTvl: BigNumber,
	bentStaked: BigNumber,
	bentStakedUsd: BigNumber,
	bentEarnedUsd: BigNumber,
	bentAllowance: BigNumber,
	bentRewards: Record<string, BigNumber>,
	bentRewardsUsd: Record<string, BigNumber>,
	bentAvgApr: number,
	bentAprs: Record<string, number>,
	bentTotalStaked: BigNumber,

	// BentCVX Staking Pool States
	bentCvxAllowance: BigNumber,
	vlCvxBalance: BigNumber,
	bentCvxStakingAllowance: BigNumber,
	bentCvxStaked: BigNumber,
	bentCvxTotalStaked: BigNumber,
	bentCvxTvl: BigNumber,
	bentCvxRewards: Record<string, BigNumber[]>,
	bentCvxRewardsUsd: Record<string, BigNumber[]>,
	bentCvxEarned: Record<string, BigNumber>,
	bentCvxAprs: Record<string, number[]>,
	bentCvxPoolAprs: Record<string, number>,
	bentCvxAvgApr: number,

	// BentCVX Staking Pool V2 States
	bentCvxAllowanceV2: BigNumber,
	cvxStakingAllowanceV2: BigNumber,
	vlCvxBalanceV2: BigNumber,
	bentCvxStakingAllowanceV2: BigNumber,
	bentCvxStakedV2: BigNumber,
	bentCvxTotalStakedV2: BigNumber,
	bentCvxTvlV2: BigNumber,
	bentCvxRewardsV2: Record<string, BigNumber[]>,
	bentCvxRewardsUsdV2: Record<string, BigNumber[]>,
	bentCvxEarnedV2: Record<string, BigNumber>,
	bentCvxAprsV2: Record<string, number[]>,
	bentCvxPoolAprsV2: Record<string, number>,
	bentCvxAvgAprV2: number,

	// Bent Staking Pool V2 States
	bentBalanceV2: BigNumber,
	bentStakingAllowanceV2: BigNumber,
	bentStakedV2: BigNumber,
	bentTotalStakedV2: BigNumber,
	bentTvlV2: BigNumber,
	bentRewardsV2: Record<string, BigNumber[]>,
	bentRewardsUsdV2: Record<string, BigNumber[]>,
	bentEarnedV2: Record<string, BigNumber>,
	bentAprsV2: Record<string, number[]>,
	bentPoolAprsV2: Record<string, number>,
	bentAvgAprV2: number,

	// BentCVXLp Staking Pool V2 States
	bentCVXLpBalanceV2: BigNumber,
	bentCVXLpStakingAllowanceV2: BigNumber,
	bentCVXLpStakedV2: BigNumber,
	bentCVXLpTotalStakedV2: BigNumber,
	bentCVXLpTvlV2: BigNumber,
	bentCVXLpRewardsV2: Record<string, BigNumber[]>,
	bentCVXLpRewardsUsdV2: Record<string, BigNumber[]>,
	bentCVXLpEarnedV2: Record<string, BigNumber>,
	bentCVXLpAprsV2: Record<string, number[]>,
	bentCVXLpPoolAprsV2: Record<string, number>,
	bentCVXLpAvgAprV2: number,

	// Curve Pool States
	crvTvl: Record<string, BigNumber>;
	crvApr: Record<string, number>;
	crvDeposit: Record<string, BigNumber>;
	crvPoolRewards: Record<string, BigNumber[]>;	// Crv Pool Rewards
	bentPoolRewardsInfo: Record<string, BentPoolReward[]>;
	crvLpAllowance: Record<string, BigNumber>;
	crvEarnedUsd: Record<string, BigNumber>;
	crvDepositedUsd: Record<string, BigNumber>;
	crvProjectedApr: Record<string, CrvApy>;
	crvEndRewardBlock: Record<string, BigNumber>;

	// Sushi Pool States
	sushiTvl: Record<string, BigNumber>;
	sushiApr: Record<string, number>;
	sushiRewards: Record<string, BigNumber>;
	sushiEarnedUsd: Record<string, BigNumber>;
	sushiDepositedUsd: Record<string, BigNumber>;
	sushiLpDeposited: Record<string, BigNumber>;

	// Uniswap Pool States
	uniswapTvl: Record<string, BigNumber>;
	uniswapApr: Record<string, number>;
	uniswapRewards: Record<string, BigNumber>;
	uniswapEarnedUsd: Record<string, BigNumber>;
	uniswapDepositedUsd: Record<string, BigNumber>;
	uniswapLpDeposited: Record<string, BigNumber>;

	// Snapshot Voting Info
	voters: Voter[];
	totalVp: number;
}

const initialState: ContractsState = {
	gas: ethers.constants.Zero,

	bentCirculatingSupply: ethers.constants.Zero,
	tokenPrices: {},

	balances: {},
	totalSupplies: {},

	weBentAllowance: ethers.constants.Zero,
	weBentBalance: ethers.constants.Zero,
	weBentLocked: ethers.constants.Zero,
	weBentTotalSupply: ethers.constants.Zero,
	weBentBentBalance: ethers.constants.Zero,
	weBentTvl: ethers.constants.Zero,
	weBentLockedData: [],
	weBentUnlockable: ethers.constants.Zero,
	weBentLockDuration: ethers.constants.Zero,
	weBentAvgApr: 0,
	weBentApr: 0,
	weBentEarnedUsd: ethers.constants.Zero,
	weBentAprs: {},
	weBentRewards: {},
	weBentRewardsUsd: {},
	delegationAddr: ethers.constants.AddressZero,

	bentTvl: ethers.constants.Zero,
	bentStaked: ethers.constants.Zero,
	bentStakedUsd: ethers.constants.Zero,
	bentEarnedUsd: ethers.constants.Zero,
	bentAllowance: ethers.constants.Zero,
	bentRewards: {},
	bentRewardsUsd: {},
	bentAprs: {},
	bentAvgApr: 0,
	bentTotalStaked: ethers.constants.Zero,

	bentCvxAllowance: ethers.constants.Zero,
	vlCvxBalance: ethers.constants.Zero,
	bentCvxStakingAllowance: ethers.constants.Zero,
	bentCvxStaked: ethers.constants.Zero,
	bentCvxTotalStaked: ethers.constants.Zero,
	bentCvxTvl: ethers.constants.Zero,
	bentCvxRewards: {},
	bentCvxRewardsUsd: {},
	bentCvxEarned: {},
	bentCvxAprs: {},
	bentCvxPoolAprs: {},
	bentCvxAvgApr: 0,

	bentCvxAllowanceV2: ethers.constants.Zero,
	cvxStakingAllowanceV2: ethers.constants.Zero,
	vlCvxBalanceV2: ethers.constants.Zero,
	bentCvxStakingAllowanceV2: ethers.constants.Zero,
	bentCvxStakedV2: ethers.constants.Zero,
	bentCvxTotalStakedV2: ethers.constants.Zero,
	bentCvxTvlV2: ethers.constants.Zero,
	bentCvxRewardsV2: {},
	bentCvxRewardsUsdV2: {},
	bentCvxEarnedV2: {},
	bentCvxAprsV2: {},
	bentCvxPoolAprsV2: {},
	bentCvxAvgAprV2: 0,

	bentBalanceV2: ethers.constants.Zero,
	bentStakingAllowanceV2: ethers.constants.Zero,
	bentStakedV2: ethers.constants.Zero,
	bentTotalStakedV2: ethers.constants.Zero,
	bentTvlV2: ethers.constants.Zero,
	bentRewardsV2: {},
	bentRewardsUsdV2: {},
	bentEarnedV2: {},
	bentAprsV2: {},
	bentPoolAprsV2: {},
	bentAvgAprV2: 0,

	bentCVXLpBalanceV2: ethers.constants.Zero,
	bentCVXLpStakingAllowanceV2: ethers.constants.Zero,
	bentCVXLpStakedV2: ethers.constants.Zero,
	bentCVXLpTotalStakedV2: ethers.constants.Zero,
	bentCVXLpTvlV2: ethers.constants.Zero,
	bentCVXLpRewardsV2: {},
	bentCVXLpRewardsUsdV2: {},
	bentCVXLpEarnedV2: {},
	bentCVXLpAprsV2: {},
	bentCVXLpPoolAprsV2: {},
	bentCVXLpAvgAprV2: 0,

	crvTvl: {},
	crvApr: {},
	crvDeposit: {},
	crvPoolRewards: {},
	bentPoolRewardsInfo: {},
	crvLpAllowance: {},
	crvEarnedUsd: {},
	crvDepositedUsd: {},
	crvProjectedApr: {},
	crvEndRewardBlock: {},

	sushiTvl: {},
	sushiApr: {},
	sushiRewards: {},
	sushiEarnedUsd: {},
	sushiDepositedUsd: {},
	sushiLpDeposited: {},

	uniswapTvl: {},
	uniswapApr: {},
	uniswapRewards: {},
	uniswapEarnedUsd: {},
	uniswapDepositedUsd: {},
	uniswapLpDeposited: {},

	voters: [],
	totalVp: 0,
};

export default createReducer(initialState, (builder) =>
	builder
		.addCase(updateContractInfo, (state, action) => {
			state.gas = action.payload.gas;

			Object.keys(action.payload.tokenPrices).forEach(tokenAddr => {
				state.tokenPrices[tokenAddr.toLowerCase()] = action.payload.tokenPrices[tokenAddr];
			})
			Object.keys(action.payload.balances).forEach(tokenAddr => {
				state.balances[tokenAddr.toLowerCase()] = action.payload.balances[tokenAddr];
			})
			Object.keys(action.payload.totalSupplies).forEach(tokenAddr => {
				state.totalSupplies[tokenAddr.toLowerCase()] = action.payload.totalSupplies[tokenAddr];
			})

			// Crv Pools
			Object.keys(action.payload.crvTvl).forEach(poolKey => {
				state.crvTvl[poolKey] = action.payload.crvTvl[poolKey];
			})
			Object.keys(action.payload.crvApr).forEach(poolKey => {
				state.crvApr[poolKey] = action.payload.crvApr[poolKey];
			})
			Object.keys(action.payload.crvDeposit).forEach(poolKey => {
				state.crvDeposit[poolKey] = action.payload.crvDeposit[poolKey];
			})
			Object.keys(action.payload.crvPoolRewards).forEach(poolKey => {
				state.crvPoolRewards[poolKey] = action.payload.crvPoolRewards[poolKey];
			})
			Object.keys(action.payload.bentPoolRewardsInfo).forEach(poolKey => {
				state.bentPoolRewardsInfo[poolKey] = action.payload.bentPoolRewardsInfo[poolKey];
			})
			Object.keys(action.payload.crvLpAllowance).forEach(poolKey => {
				state.crvLpAllowance[poolKey] = action.payload.crvLpAllowance[poolKey];
			})
			Object.keys(action.payload.crvEarnedUsd).forEach(poolKey => {
				state.crvEarnedUsd[poolKey] = action.payload.crvEarnedUsd[poolKey];
			})
			Object.keys(action.payload.crvDepositedUsd).forEach(poolKey => {
				state.crvDepositedUsd[poolKey] = action.payload.crvDepositedUsd[poolKey];
			})
			Object.keys(action.payload.crvProjectedApr).forEach(poolKey => {
				if (!state.crvProjectedApr) state.crvProjectedApr = {};
				state.crvProjectedApr[poolKey] = action.payload.crvProjectedApr[poolKey];
			})
			Object.keys(action.payload.crvEndRewardBlock).forEach(poolKey => {
				if (!state.crvEndRewardBlock) state.crvEndRewardBlock = {};
				state.crvEndRewardBlock[poolKey] = action.payload.crvEndRewardBlock[poolKey];
			})

			// Sushi Pools
			Object.keys(action.payload.sushiTvl).forEach(poolKey => {
				state.sushiTvl[poolKey] = action.payload.sushiTvl[poolKey];
			})
			Object.keys(action.payload.sushiApr).forEach(poolKey => {
				state.sushiApr[poolKey] = action.payload.sushiApr[poolKey];
			})
			Object.keys(action.payload.sushiLpDeposited).forEach(poolKey => {
				state.sushiLpDeposited[poolKey] = action.payload.sushiLpDeposited[poolKey];
			})
			Object.keys(action.payload.sushiEarnedUsd).forEach(poolKey => {
				state.sushiEarnedUsd[poolKey] = action.payload.sushiEarnedUsd[poolKey];
			})
			Object.keys(action.payload.sushiDepositedUsd).forEach(poolKey => {
				state.sushiDepositedUsd[poolKey] = action.payload.sushiDepositedUsd[poolKey];
			})
			Object.keys(action.payload.sushiRewards).forEach(poolKey => {
				state.sushiRewards[poolKey] = action.payload.sushiRewards[poolKey];
			})

			// Uniswap Pools
			Object.keys(action.payload.uniswapTvl).forEach(poolKey => {
				state.uniswapTvl[poolKey] = action.payload.uniswapTvl[poolKey];
			})
			Object.keys(action.payload.uniswapApr).forEach(poolKey => {
				state.uniswapApr[poolKey] = action.payload.uniswapApr[poolKey];
			})
			Object.keys(action.payload.uniswapLpDeposited).forEach(poolKey => {
				state.uniswapLpDeposited[poolKey] = action.payload.uniswapLpDeposited[poolKey];
			})
			Object.keys(action.payload.uniswapEarnedUsd).forEach(poolKey => {
				state.uniswapEarnedUsd[poolKey] = action.payload.uniswapEarnedUsd[poolKey];
			})
			Object.keys(action.payload.uniswapDepositedUsd).forEach(poolKey => {
				state.uniswapDepositedUsd[poolKey] = action.payload.uniswapDepositedUsd[poolKey];
			})
			Object.keys(action.payload.uniswapRewards).forEach(poolKey => {
				state.uniswapRewards[poolKey] = action.payload.uniswapRewards[poolKey];
			})

			// Bent Staking Pool
			state.bentTvl = action.payload.bentTvl;
			state.bentAllowance = action.payload.bentAllowance;
			state.bentStakedUsd = action.payload.bentStakedUsd;
			state.bentStaked = action.payload.bentStaked;
			state.bentAvgApr = action.payload.bentAvgApr;
			state.bentEarnedUsd = action.payload.bentEarnedUsd;
			state.bentTotalStaked = action.payload.bentTotalStaked;
			state.bentCirculatingSupply = action.payload.bentCirculatingSupply;
			Object.keys(action.payload.bentAprs).forEach(poolKey => {
				state.bentAprs[poolKey] = action.payload.bentAprs[poolKey];
			})
			Object.keys(action.payload.bentRewards).forEach(tokenAddr => {
				state.bentRewards[tokenAddr] = action.payload.bentRewards[tokenAddr];
			})
			Object.keys(action.payload.bentRewardsUsd).forEach(tokenAddr => {
				state.bentRewardsUsd[tokenAddr] = action.payload.bentRewardsUsd[tokenAddr];
			})

			// bentCVX
			state.bentCvxAllowance = action.payload.bentCvxAllowance;
			state.vlCvxBalance = action.payload.vlCvxBalance;
			state.bentCvxStakingAllowance = action.payload.bentCvxStakingAllowance
			state.bentCvxStaked = action.payload.bentCvxStaked;
			state.bentCvxTotalStaked = action.payload.bentCvxTotalStaked;
			state.bentCvxTvl = action.payload.bentCvxTvl;
			Object.keys(action.payload.bentCvxRewards).forEach(poolKey => {
				if (!state.bentCvxRewards) state.bentCvxRewards = {}
				state.bentCvxRewards[poolKey] = action.payload.bentCvxRewards[poolKey];
			})
			Object.keys(action.payload.bentCvxRewardsUsd).forEach(poolKey => {
				if (!state.bentCvxRewardsUsd) state.bentCvxRewardsUsd = {}
				state.bentCvxRewardsUsd[poolKey] = action.payload.bentCvxRewardsUsd[poolKey];
			})
			Object.keys(action.payload.bentCvxEarned).forEach(poolKey => {
				if (!state.bentCvxEarned) state.bentCvxEarned = {}
				state.bentCvxEarned[poolKey] = action.payload.bentCvxEarned[poolKey];
			})
			Object.keys(action.payload.bentCvxAprs).forEach(poolKey => {
				if (!state.bentCvxAprs) state.bentCvxAprs = {}
				state.bentCvxAprs[poolKey] = action.payload.bentCvxAprs[poolKey];
			})
			Object.keys(action.payload.bentCvxPoolAprs).forEach(poolKey => {
				if (!state.bentCvxPoolAprs) state.bentCvxPoolAprs = {}
				state.bentCvxPoolAprs[poolKey] = action.payload.bentCvxPoolAprs[poolKey];
			})
			state.bentCvxAvgApr = action.payload.bentCvxAvgApr;

			// bentCVX V2
			state.bentCvxAllowanceV2 = action.payload.bentCvxAllowanceV2;
			state.vlCvxBalanceV2 = action.payload.vlCvxBalanceV2;
			state.bentCvxStakingAllowanceV2 = action.payload.bentCvxStakingAllowanceV2
			state.cvxStakingAllowanceV2 = action.payload.cvxStakingAllowanceV2
			state.bentCvxStakedV2 = action.payload.bentCvxStakedV2;
			state.bentCvxTotalStakedV2 = action.payload.bentCvxTotalStakedV2;
			state.bentCvxTvlV2 = action.payload.bentCvxTvlV2;
			Object.keys(action.payload.bentCvxRewardsV2).forEach(poolKey => {
				if (!state.bentCvxRewardsV2) state.bentCvxRewardsV2 = {}
				state.bentCvxRewardsV2[poolKey] = action.payload.bentCvxRewardsV2[poolKey];
			})
			Object.keys(action.payload.bentCvxRewardsUsdV2).forEach(poolKey => {
				if (!state.bentCvxRewardsUsdV2) state.bentCvxRewardsUsdV2 = {}
				state.bentCvxRewardsUsdV2[poolKey] = action.payload.bentCvxRewardsUsdV2[poolKey];
			})
			Object.keys(action.payload.bentCvxEarnedV2).forEach(poolKey => {
				if (!state.bentCvxEarnedV2) state.bentCvxEarnedV2 = {}
				state.bentCvxEarnedV2[poolKey] = action.payload.bentCvxEarnedV2[poolKey];
			})
			Object.keys(action.payload.bentCvxAprsV2).forEach(poolKey => {
				if (!state.bentCvxAprsV2) state.bentCvxAprsV2 = {}
				state.bentCvxAprsV2[poolKey] = action.payload.bentCvxAprsV2[poolKey];
			})
			Object.keys(action.payload.bentCvxPoolAprsV2).forEach(poolKey => {
				if (!state.bentCvxPoolAprsV2) state.bentCvxPoolAprsV2 = {}
				state.bentCvxPoolAprsV2[poolKey] = action.payload.bentCvxPoolAprsV2[poolKey];
			})
			state.bentCvxAvgAprV2 = action.payload.bentCvxAvgAprV2;

			// Bent Staking V2
			state.bentStakingAllowanceV2 = action.payload.bentStakingAllowanceV2
			state.bentStakedV2 = action.payload.bentStakedV2;
			state.bentTotalStakedV2 = action.payload.bentTotalStakedV2;
			state.bentTvlV2 = action.payload.bentTvlV2;
			Object.keys(action.payload.bentRewardsV2).forEach(poolKey => {
				if (!state.bentRewardsV2) state.bentRewardsV2 = {}
				state.bentRewardsV2[poolKey] = action.payload.bentRewardsV2[poolKey];
			})
			Object.keys(action.payload.bentRewardsUsdV2).forEach(poolKey => {
				if (!state.bentRewardsUsdV2) state.bentRewardsUsdV2 = {}
				state.bentRewardsUsdV2[poolKey] = action.payload.bentRewardsUsdV2[poolKey];
			})
			Object.keys(action.payload.bentEarnedV2).forEach(poolKey => {
				if (!state.bentEarnedV2) state.bentEarnedV2 = {}
				state.bentEarnedV2[poolKey] = action.payload.bentEarnedV2[poolKey];
			})
			Object.keys(action.payload.bentAprsV2).forEach(poolKey => {
				if (!state.bentAprsV2) state.bentAprsV2 = {}
				state.bentAprsV2[poolKey] = action.payload.bentAprsV2[poolKey];
			})
			Object.keys(action.payload.bentPoolAprsV2).forEach(poolKey => {
				if (!state.bentPoolAprsV2) state.bentPoolAprsV2 = {}
				state.bentPoolAprsV2[poolKey] = action.payload.bentPoolAprsV2[poolKey];
			})
			state.bentAvgAprV2 = action.payload.bentAvgAprV2;

			// BentCVXLp Staking V2
			state.bentCVXLpStakingAllowanceV2 = action.payload.bentCVXLpStakingAllowanceV2
			state.bentCVXLpStakedV2 = action.payload.bentCVXLpStakedV2;
			state.bentCVXLpTotalStakedV2 = action.payload.bentCVXLpTotalStakedV2;
			state.bentCVXLpTvlV2 = action.payload.bentCVXLpTvlV2;
			Object.keys(action.payload.bentCVXLpRewardsV2).forEach(poolKey => {
				if (!state.bentCVXLpRewardsV2) state.bentCVXLpRewardsV2 = {}
				state.bentCVXLpRewardsV2[poolKey] = action.payload.bentCVXLpRewardsV2[poolKey];
			})
			Object.keys(action.payload.bentCVXLpRewardsUsdV2).forEach(poolKey => {
				if (!state.bentCVXLpRewardsUsdV2) state.bentCVXLpRewardsUsdV2 = {}
				state.bentCVXLpRewardsUsdV2[poolKey] = action.payload.bentCVXLpRewardsUsdV2[poolKey];
			})
			Object.keys(action.payload.bentCVXLpEarnedV2).forEach(poolKey => {
				if (!state.bentCVXLpEarnedV2) state.bentCVXLpEarnedV2 = {}
				state.bentCVXLpEarnedV2[poolKey] = action.payload.bentCVXLpEarnedV2[poolKey];
			})
			Object.keys(action.payload.bentCVXLpAprsV2).forEach(poolKey => {
				if (!state.bentCVXLpAprsV2) state.bentCVXLpAprsV2 = {}
				state.bentCVXLpAprsV2[poolKey] = action.payload.bentCVXLpAprsV2[poolKey];
			})
			Object.keys(action.payload.bentCVXLpPoolAprsV2).forEach(poolKey => {
				if (!state.bentCVXLpPoolAprsV2) state.bentCVXLpPoolAprsV2 = {}
				state.bentCVXLpPoolAprsV2[poolKey] = action.payload.bentCVXLpPoolAprsV2[poolKey];
			})
			state.bentCVXLpAvgAprV2 = action.payload.bentCVXLpAvgAprV2;

			// weBent
			state.weBentAllowance = action.payload.weBentAllowance;
			state.weBentBalance = action.payload.weBentBalance;
			state.weBentLocked = action.payload.weBentLocked;
			state.weBentTotalSupply = action.payload.weBentTotalSupply;
			state.weBentBentBalance = action.payload.weBentBentBalance;
			state.weBentTvl = action.payload.weBentTvl;
			state.weBentLockedData = action.payload.weBentLockedData;
			state.weBentUnlockable = action.payload.weBentUnlockable;
			state.weBentLockDuration = action.payload.weBentLockDuration;
			state.weBentEarnedUsd = action.payload.weBentEarnedUsd;
			state.weBentAvgApr = action.payload.weBentAvgApr;
			state.weBentApr = action.payload.weBentApr;
			Object.keys(action.payload.weBentAprs).forEach(poolKey => {
				if (!state.weBentAprs) state.weBentAprs = {};
				state.weBentAprs[poolKey] = action.payload.weBentAprs[poolKey];
			})
			Object.keys(action.payload.weBentRewards).forEach(tokenAddr => {
				if (!state.weBentRewards) state.weBentRewards = {};
				state.weBentRewards[tokenAddr] = action.payload.weBentRewards[tokenAddr];
			})
			Object.keys(action.payload.weBentRewardsUsd).forEach(tokenAddr => {
				if (!state.weBentRewardsUsd) state.weBentRewardsUsd = {};
				state.weBentRewardsUsd[tokenAddr] = action.payload.weBentRewardsUsd[tokenAddr];
			})

			// Snapshot Vote Info
			state.delegationAddr = action.payload.delegationAddr;
			state.voters = action.payload.voters;
			state.totalVp = action.payload.totalVp;
		})
);
