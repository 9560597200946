import React, { ReactNode } from 'react'
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import Updaters from 'state/Updater'
import store from 'state'

import type { MetaMask } from '@web3-react/metamask'
import type { Network } from '@web3-react/network'
import type { WalletConnect } from '@web3-react/walletconnect-v2'

import { hooks as metaMaskHooks, metaMask } from '../connectors/metaMask'
import { hooks as networkHooks, network } from '../connectors/network'
import { hooks as walletConnectV2Hooks, walletConnectV2 } from '../connectors/walletConnect'

const connectors: any = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [network, networkHooks],
]

interface Props {
  children: ReactNode
}

const Providers = ({ children }: Props): React.ReactElement => {
  return (
    <Web3ReactProvider connectors={connectors}>
      <Provider store={store}>
        <HelmetProvider>
          <Updaters />
          {children}
        </HelmetProvider>
      </Provider>
    </Web3ReactProvider>
  )
}

export default Providers
