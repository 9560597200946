import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Container,
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardText,
  Input,
  Label,
  CardBody,
  Spinner,
} from 'reactstrap'
import classnames from 'classnames'
import { POOLS, TOKENS, TOKEN_LOGO } from 'constant'
import { formatBigNumber, formatMillionsBigNumber, getEtherscanLink } from 'utils'
import {
  useActiveWeb3React,
  useBalance,
  useERC20Contract,
  useBentCVXLpPoolAprV2,
  useBentCVXLpStakedUSDV2,
  useBentCVXLpStakedV2,
  useBentCVXLpStakingAllowanceV2,
  useBentCVXLpTotalEarnedV2,
  useBentCVXLpTotalStakedV2,
  useBentCVXLpTvlV2,
  useBentCVXLpStakingContractV2,
} from 'hooks'
import { BigNumber, ethers, utils } from 'ethers'
import { DecimalSpan } from 'components/DecimalSpan'
import Address from 'components/Address'
import { AddToMetamask } from 'components/AddToMetamask'
import { ClaimBentRewarder } from './rewarderBent'
import { BentCVXLpAprTooltipV2 } from 'components/Tooltip'

const TxSpinner = () => {
  return (
    <React.Fragment>
      &nbsp;
      <Spinner size="sm" />
    </React.Fragment>
  )
}

export const StakeBentCVXLpV2 = (): React.ReactElement => {
  const [activeTab, setActiveTab] = useState('1')
  const [stakeAmount, setStakeAmount] = useState('')
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [isStakeApproved, setIsStakeApproved] = useState<boolean>(false)
  const [isStkApprPending, setStkApprPending] = useState<boolean>(false)
  const [isStkPending, setStkPending] = useState<boolean>(false)
  const [isUnstakePending, setUnstakePending] = useState<boolean>(false)
  const bentCVXLpToken = useERC20Contract(TOKENS['BENTCVX_LP'].ADDR)
  const bentCVXLpBalance = useBalance(TOKENS['BENTCVX_LP'].ADDR)
  const bentStakingAllowance = useBentCVXLpStakingAllowanceV2()
  const bentStaked = useBentCVXLpStakedV2()
  const bentStakedUsd = useBentCVXLpStakedUSDV2()
  const bentTotalStaked = useBentCVXLpTotalStakedV2()
  const bentPoolApr = useBentCVXLpPoolAprV2('CVX')
  const earnedUsd = useBentCVXLpTotalEarnedV2()
  const tvl = useBentCVXLpTvlV2()
  const { library } = useActiveWeb3React()
  const bentStaking = useBentCVXLpStakingContractV2()

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const onStakeMax = () => {
    setStakeAmount(formatBigNumber(bentCVXLpBalance, 18, 18).replaceAll(',', ''))
    setIsStakeApproved(bentStakingAllowance.gte(bentCVXLpBalance) && !bentCVXLpBalance.isZero())
  }

  const onWithdrawMax = () => {
    setWithdrawAmount(formatBigNumber(bentStaked, 18, 18).replaceAll(',', ''))
  }

  const onStakeAmountChange = (value) => {
    setStakeAmount(value)
    if (isNaN(parseFloat(value))) return
    const amountBN = utils.parseUnits(value, 18)
    setIsStakeApproved(bentStakingAllowance.gte(amountBN) && !amountBN.isZero())
  }

  const onWithdrawAmountChange = (value) => {
    setWithdrawAmount(value)
  }

  const onBentApprove = async () => {
    if (!library) return
    const signer = await library.getSigner()
    const tx = await bentCVXLpToken
      .connect(signer)
      .approve(POOLS.BentCVXLpStakingV2.bentCVXLpStaking, ethers.constants.MaxUint256)
    setStkApprPending(true)
    const res = await tx.wait()
    setStkApprPending(false)
    if (res) {
      setIsStakeApproved(true)
    }
  }

  const onStake = async () => {
    if (!library) return
    const signer = await library.getSigner()
    const tx = await bentStaking.connect(signer).deposit(utils.parseUnits(stakeAmount, 18))
    setStkPending(true)
    const res = await tx.wait()
    setStkPending(false)
    if (res) {
      setStakeAmount('')
      setIsStakeApproved(false)
    }
  }

  const onWithdraw = async () => {
    if (!library) return
    const signer = await library.getSigner()
    const tx = await bentStaking.connect(signer).withdraw(utils.parseUnits(withdrawAmount, 18))
    setUnstakePending(true)
    const res = await tx.wait()
    setUnstakePending(false)
    if (res) {
      setWithdrawAmount('')
    }
  }

  return (
    <Container className="stake-bent bentCVX">
      <Row>
        <Col md="12">
          <div className="convert-up">
            <h2 className="section-header">New bentCVX Curve Pool</h2>
            <div className="toggleWrap tokentable table">
              <Row className="align-items-center thead">
                <Col className="px-0">
                  <div className="imgText">
                    <img src={TOKEN_LOGO.CRV} alt="" width="28" />
                    <h2>bentCVX/CVX</h2>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span className="small p-0">Earned (USD)</span>
                    <br />
                    <b className="p-0">
                      <span className="small">$</span>
                      <DecimalSpan value={formatBigNumber(earnedUsd, 18, 2)} />
                      <i className="fa fa-caret-down opacity-0" aria-hidden="true" />
                    </b>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span className="small p-0">APR</span>
                    <br />
                    <b className="p-0">
                      {bentPoolApr ? <>{utils.commify(bentPoolApr.toFixed(2))}%</> : 'TBC'}
                      &nbsp;
                      <i
                        className="fa fa-info-circle cursor-pointer"
                        id="bentCVXLp-apr-info-v2"
                        aria-hidden="true"
                      />
                      <BentCVXLpAprTooltipV2 />
                    </b>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span className="small p-0">
                      My Staked (
                      {bentStaked.isZero() ? '--' : formatBigNumber(bentStaked, 18, 0)} bentCVX
                      LP)
                    </span>
                    <br />
                    <b className="p-0">
                      <span className="small">$</span>
                      {formatBigNumber(bentStakedUsd, 18, 0)}
                      <i className="fa fa-caret-down opacity-0" aria-hidden="true" />
                    </b>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span className="small p-0">
                      TVL ({formatBigNumber(bentTotalStaked, 18, 2)} bentCVX LP)
                    </span>
                    <br />
                    <b className="p-0">
                      <span className="small">$</span>
                      {formatMillionsBigNumber(tvl, 18, 2)}
                      <i className="fa fa-caret-down opacity-0" aria-hidden="true" />
                    </b>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <div className="innerAccordian">
                    <div className="converttabs">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => toggle('1')}
                          >
                            Stake
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => toggle('2')}
                          >
                            Claim
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => toggle('3')}
                          >
                            Unstake
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '4' })}
                            onClick={() => toggle('4')}
                          >
                            Info
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="6" className="inverse">
                              <Card body>
                                <CardText>
                                  Deposit liquidity into the&nbsp;
                                  <a
                                    href="https://curve.fi/#/ethereum/pools/factory-v2-355/deposit"
                                    target="_blank"
                                    className="contract-address"
                                    rel="noreferrer"
                                  >
                                    Curve bentCVX pool
                                  </a>
                                  &nbsp;(without staking in the Curve gauge), and then stake
                                  your bentCVX LP tokens here to earn CVX.
                                </CardText>
                              </Card>
                            </Col>
                            <Col sm="6" className="divider-left">
                              <Card body>
                                <div className="card-text">
                                  <div className="amount-crv">
                                    <p className="labeltext">
                                      <Label>Amount</Label>
                                      <Label>
                                        Available: {formatBigNumber(bentCVXLpBalance)}
                                      </Label>
                                    </p>
                                    <div className="amountinput">
                                      <Input
                                        type="number"
                                        placeholder="0"
                                        onChange={(e) => onStakeAmountChange(e.target.value)}
                                        value={stakeAmount}
                                      />
                                      <img
                                        src={TOKEN_LOGO.CVX}
                                        alt="input-logo"
                                        className="inputlogo"
                                      />
                                      <Button className="maxbtn" onClick={onStakeMax}>
                                        Max
                                      </Button>
                                    </div>
                                    <div className="btnouter">
                                      <p className="lineup"></p>
                                      <div className="btnwrapper">
                                        <Button
                                          className="approvebtn"
                                          disabled={
                                            bentCVXLpBalance.isZero() ||
                                            isStakeApproved ||
                                            parseFloat(stakeAmount) === 0 ||
                                            isNaN(parseFloat(stakeAmount)) ||
                                            utils
                                              .parseUnits(stakeAmount, 18)
                                              .gt(bentCVXLpBalance) ||
                                            isStkApprPending
                                          }
                                          onClick={onBentApprove}
                                        >
                                          Approve{isStkApprPending && <TxSpinner />}
                                        </Button>
                                        <Button
                                          className="approvebtn"
                                          disabled={
                                            bentCVXLpBalance.isZero() ||
                                            !isStakeApproved ||
                                            parseFloat(stakeAmount) === 0 ||
                                            isNaN(parseFloat(stakeAmount)) ||
                                            utils
                                              .parseUnits(stakeAmount, 18)
                                              .gt(bentCVXLpBalance) ||
                                            isStkPending
                                          }
                                          onClick={onStake}
                                        >
                                          Stake{isStkPending && <TxSpinner />}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <ClaimBentRewarder
                              poolKey="CVX"
                              poolInfo={POOLS.BentCVXLpStakingV2.BentCVXLpRewarder}
                            />
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col md="12" className="inverse">
                              <Card body>
                                <div className="card-text d-flex row">
                                  <div className="amount-crv col-md-5">
                                    <p className="labeltext">
                                      <Label>Amount</Label>
                                      <Label>Deposited: {formatBigNumber(bentStaked)}</Label>
                                    </p>
                                    <div className="amountinput">
                                      <Input
                                        type="number"
                                        placeholder="0"
                                        onChange={(e) => onWithdrawAmountChange(e.target.value)}
                                        value={withdrawAmount}
                                      />
                                      <img
                                        src={TOKENS['CVX'].LOGO}
                                        alt="input-logo"
                                        className="inputlogo"
                                      />
                                      <Button className="maxbtn" onClick={onWithdrawMax}>
                                        Max
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="amount-crv col-md-5">
                                    <p className="labeltext">
                                      <Label>&nbsp;</Label>
                                    </p>
                                    <Button
                                      className="approvebtn"
                                      disabled={
                                        bentStaked.isZero() ||
                                        parseFloat(withdrawAmount) === 0 ||
                                        isNaN(parseFloat(withdrawAmount)) ||
                                        utils.parseUnits(withdrawAmount, 18).gt(bentStaked) ||
                                        isUnstakePending
                                      }
                                      onClick={onWithdraw}
                                    >
                                      Unstake{isUnstakePending && <TxSpinner />}
                                    </Button>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="4">
                          <Row>
                            <Col sm="12">
                              <Card body>
                                <div className="infoWrap">
                                  <Card body></Card>
                                  <Row>
                                    <Col md="3">
                                      <CardText className="mt-0">
                                        <b>bentCVX LP</b> token address:
                                      </CardText>
                                    </Col>
                                    <Col md="9" className="d-flex">
                                      <a
                                        href={getEtherscanLink(TOKENS.BENTCVX_LP.ADDR)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Address address={TOKENS.BENTCVX_LP.ADDR} />
                                      </a>
                                      <AddToMetamask
                                        address={TOKENS.BENTCVX_LP.ADDR}
                                        symbol={TOKENS.BENTCVX_LP.SYMBOL}
                                        img={TOKENS.BENTCVX_LP.EXT_LOGO}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3">
                                      <CardText className="mt-0">
                                        <b>CVX</b> token address:
                                      </CardText>
                                    </Col>
                                    <Col md="9">
                                      <a
                                        href={getEtherscanLink(TOKENS.CVX.ADDR)}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Address address={TOKENS.CVX.ADDR} />
                                      </a>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3">
                                      <CardText className="mt-0">
                                        Deposit contract address:
                                      </CardText>
                                    </Col>
                                    <Col md="9">
                                      <a
                                        href={getEtherscanLink(
                                          POOLS.BentCVXLpStakingV2.bentCVXLpStaking,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Address
                                          address={POOLS.BentCVXLpStakingV2.bentCVXLpStaking}
                                        />
                                      </a>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3">
                                      <CardText className="mt-0">
                                        Rewards contract address:
                                      </CardText>
                                    </Col>
                                    <Col md="9">
                                      <a
                                        href={getEtherscanLink(
                                          POOLS.BentCVXLpStakingV2.BentCVXLpRewarder.Pool,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Address
                                          address={
                                            POOLS.BentCVXLpStakingV2.BentCVXLpRewarder.Pool
                                          }
                                        />
                                      </a>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const ClaimButton = styled(Button)`
  margin-left: 20px;
  height: 42px;
  width: 150px;
`
