import { BigNumber } from "ethers";
import {
	useBentCvxStakedUSD,
	useBentCvxTotalEarned,
	useBentCvxTvl,
	useBentCvxTvlV2,
	useBentCvxTotalEarnedV2,
	useBentCvxStakedUSDV2,
	useBentEarnedUsd,
	useBentStakedUsd,
	useBentTvl,
	useBentTvlV2,
	useBentTotalEarnedV2,
	useBentStakedUSDV2,
	useCrvPoolTotalDepositedUsds,
	useCrvPoolTotalEarned,
	useCrvTotalTvl,
	useSushiPoolTotalDepositedUsd,
	useSushiPoolTotalEarned,
	useSushiTotalTvl,
	useUniswapPoolTotalDepositedUsd,
	useUniswapPoolTotalEarned,
	useUniswapTotalTvl,
	useWeBentDepositsUsd,
	useWeBentEarnedUsd,
	useWeBentTvl,
	useBentCVXLpStakedUSDV2,
	useBentCVXLpTotalEarnedV2,
	useBentCVXLpTvlV2
} from ".";

export const useTotalTvl = (): BigNumber => {
	const crvTvl = useCrvTotalTvl();
	const sushiTvl = useSushiTotalTvl();
	const uniswapTvl = useUniswapTotalTvl();
	const bentTvl = useBentTvl();
	const bentTvlV2 = useBentTvlV2();
	const bentCvxTvl = useBentCvxTvl();
	const bentCvxTvlV2 = useBentCvxTvlV2();
	const bentCvxLpTvlV2 = useBentCVXLpTvlV2();
	const weBentTvl = useWeBentTvl();

	return crvTvl
		.add(sushiTvl)
		.add(uniswapTvl)
		.add(bentTvlV2)
		.add(bentTvl)
		.add(bentCvxTvl)
		.add(bentCvxTvlV2)
		.add(bentCvxLpTvlV2)
		.add(weBentTvl);
}

export const useTotalEarnings = (): BigNumber => {
	const crvEarnings = useCrvPoolTotalEarned();
	const sushiEarnings = useSushiPoolTotalEarned();
	const uniswapEarnings = useUniswapPoolTotalEarned();
	const bentEarned = useBentEarnedUsd();
	const bentEarnedV2 = useBentTotalEarnedV2();
	const bentCvxEarned = useBentCvxTotalEarned();
	const bentCvxEarnedV2 = useBentCvxTotalEarnedV2();
	const bentCVXLpEarnedV2 = useBentCVXLpTotalEarnedV2();
	const weBentEarned = useWeBentEarnedUsd();

	return crvEarnings
		.add(sushiEarnings)
		.add(uniswapEarnings)
		.add(bentEarned)
		.add(bentEarnedV2)
		.add(bentCvxEarned)
		.add(bentCvxEarnedV2)
		.add(bentCVXLpEarnedV2)
		.add(weBentEarned);
}

export const useTotalDeposits = (): BigNumber => {
	const crvDeposits = useCrvPoolTotalDepositedUsds();
	const sushiDeposits = useSushiPoolTotalDepositedUsd();
	const uniswapDeposits = useUniswapPoolTotalDepositedUsd();
	const bentDeposits = useBentStakedUsd();
	const bentDepositsV2 = useBentStakedUSDV2()
	const bentCvxDeposits = useBentCvxStakedUSD();
	const bentCvxDepositsV2 = useBentCvxStakedUSDV2();
	const bentCVXLpDepositsV2 = useBentCVXLpStakedUSDV2()
	const webentDeposits = useWeBentDepositsUsd();

	return crvDeposits
		.add(sushiDeposits)
		.add(uniswapDeposits)
		.add(bentDeposits)
		.add(bentDepositsV2)
		.add(bentCvxDeposits)
		.add(bentCvxDepositsV2)
		.add(bentCVXLpDepositsV2)
		.add(webentDeposits)
}