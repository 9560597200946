import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { authRoutes } from 'routes'
import './assets/css/style.css'
import './assets/css/mobile.css'
import './assets/css/custom-input.css'
import './assets/css/button.css'
import './App.css'
import './assets/css/progress.css'
import Page from 'pages/page'

const router = createBrowserRouter(
  authRoutes.map((route) => ({
    path: route.path,
    element: (
      <Page>
        <route.component />
      </Page>
    ),
  })),
)

const App = (): React.ReactElement => {
  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  )
}

export default App
