import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS } from '../constant/chains'

const [mainnet, ...optionalChains] = Object.keys(MAINNET_CHAINS).map(Number)

export const [walletConnectV2, hooks] = initializeConnector<any>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: '0dc01b3e1f5cb3c174098dd7f2cd9c8f',
        chains: [mainnet],
        optionalChains,
        showQrModal: true,
      },
    }),
)
