import React from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
// import { utils } from "ethers";
// import { useBentPoolAprV2 } from "hooks";

export const BentAprTooltipV2 = (): React.ReactElement => {
	// const bentPoolApr = useBentPoolAprV2('BENTCVX');

	return (
		<UncontrolledTooltip target='bent-apr-info-v2' className="bent-details" placement="bottom">
			<div style={{ padding: 15, lineHeight: '18px' }}>
				<Row>
					<Col>
						<div className="text-underline">Reward Token:</div>
						<div className="green-color">bentCVX</div>
					</Col>
				</Row>
			</div>
		</UncontrolledTooltip>
	)
}