export enum ChainId {
  Mainnet = 1,
  LocalHost = 1337,
}

export const SUPPORTED_CHAINS = [ChainId.Mainnet]

export const MAINNET_RPCS = [
  'https://rpc.payload.de',
  'https://rpc.ankr.com/eth',
  'https://eth-mainnet.g.alchemy.com/v2/oRIh0-ZpxFzU3oCdS8wq_CUQm6ISOTZM',
  // "https://eth-rpc.gateway.pokt.network",
]

export const NETWORK_CONNECTIONS = {
  [ChainId.Mainnet]: MAINNET_RPCS[Math.floor(Math.random() * MAINNET_RPCS.length)],
}

export const NETWORK_NAMES = {
  [ChainId.Mainnet]: 'mainnet',
  [ChainId.LocalHost]: 'localhost',
}

export const DEFAULT_CHAIN = ChainId.Mainnet
